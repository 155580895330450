import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Layout from "../components/layout";
import DashboardScreen from "./dashboard";
import Salir from "./salir";
import RouterConfiguracion from "./configuracion/router";
import RouterChecklist from "./checklist/router";
import RouterUsuario from "./usuario/router";

const RouterPage = () => {
  const { path } = useRouteMatch();
  // console.log('routerPage');
  // console.log(path);
  return (
    <Switch>
      <Layout>
        <Route path={`${path}checklist`}>
          <RouterChecklist />
        </Route>
        <Route path={`${path}configuracion`}>
          <RouterConfiguracion />
        </Route>
        <Route path={`${path}usuario`}>
          <RouterUsuario />
        </Route>
        <Route path={`${path}salir`}>
          <Salir />
        </Route>
        <Route exact path={`${path}`}>
          <DashboardScreen />
        </Route>
      </Layout>
    </Switch>
  );
};

export default RouterPage;
