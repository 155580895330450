import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ChecklistScreen from ".";
import LayoutChecklist from "../../components/layout-checklist";
import PreguntasChecklist from "./preguntas-checklist";
import FormularioChecklistScreen from "./formulario-checklist";
import RespuestasChecklist from "./respuestas-checklist";
import DetalleRespuestasChecklist from "./detalle-respuestas-checklist";

const RouterChecklist = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutChecklist>
      <Switch>

        {/* <Route exact path={`${path}/gestionar-tipo-combustible/listar-tipo-combustible`}>
        <ListarTipoCombustible />
      </Route>
      <Route exact path={`${path}/gestionar-tipo-combustible/tipo-combustible`}>
        <TipoCombustible />
      </Route> */}

        <Route exact path={`${path}/formulario-checklist`}>
          <FormularioChecklistScreen />
        </Route>

        <Route exact path={`${path}/preguntas-checklist`}>
          <PreguntasChecklist />
        </Route>

        <Route exact path={`${path}/respuestas-checklist`}>
          <RespuestasChecklist />
        </Route>

        <Route exact path={`${path}/detalle-respuestas-checklist`}>
          <DetalleRespuestasChecklist />
        </Route>

        <Route exact path={`${path}`}>
          <ChecklistScreen />
        </Route>


      </Switch>
    </LayoutChecklist>
  );
};

export default RouterChecklist;
