import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card";
import { crearSubTipoUsuarioWeb, modificarSubTipoUsuarioWeb } from "../../../api/sub-tipo-usuario-web";


export default function SubTipoUsuarioWeb() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [row, setRow] = useState(null);
  const [titulo, setTitulo] = useState("");

  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setRow(data.params);
        setTitulo(data.titulo);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setRow({ ...row, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    // lógica para guardar los cambios
    console.log('row');
    console.log(row);
    let objEnviar = {};
    objEnviar.descripcion = row.descripcion;
    objEnviar.id = row.id;
    objEnviar.nota = row.nota;
    if (row.id <= 0) {
      // console.log('crear correo');
      let creado = await crearSubTipoUsuarioWeb(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (creado === true) {
        alert('Se creo correctamente');
        handleCancelClick();
      }
      if (creado === false) {
        alert('No se pudo crear');
      }
    }
    if (row.id > 0) {
      // console.log('actualizar correo');
      let modificado = await modificarSubTipoUsuarioWeb(objEnviar);
      // console.log('modificado');
      // console.log(modificado);
      if (modificado === true) {
        alert('Se modifico correctamente');
        handleCancelClick();
      }
      if (modificado === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && row === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {titulo}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="descripcion"
                    className="block text-md font-medium text-gray-700"
                  >
                    SUB TIPO USUARIO WEB
                  </label>
                  <div className="mt-1">
                    <input
                      id="descripcion"
                      name="descripcion"
                      type="text"
                      value={row.descripcion}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={127}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="nota"
                    className="block text-md font-medium text-gray-700"
                  >
                    NOTA
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="nota"
                      name="nota"
                      type="text"
                      value={row.nota}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      maxLength={1023}
                      rows={3}
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('SubTipoUsuarioWeb render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
