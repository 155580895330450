import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
// import { useHistory } from "react-router-dom";

export default function DashboardScreen(props) {

    // const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {

            setCargandoPage(false);
            setTieneAccesoAlModulo(true);
        } catch (err) {
            setTieneAccesoAlModulo(false);
            console.log('iniciar err');
            console.log(err);
        }
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div className="m-8">
                    <label>DASHBOARD</label>
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}