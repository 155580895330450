// import moment from "moment/moment";
import { FAILURE, POST, PUT, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerFormularioChecklist(obj) {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/formulario-checklist/obtener-formulario-checklist/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerFormularioChecklist err");
        console.log(err);
        return null;
    }
}

export async function guardarInputFormularioChecklist(obj) {
    try {
        let guardo = { error: false, id: -1 };
        const { code, id } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/formulario-checklist/guardar-formulario-checklist/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        console.log('id');
        console.log(id);
        if (code === SUCCESS) {
            guardo.id = id;
        }
        return guardo;
    } catch (err) {
        console.log("obtenerFormularioChecklist err");
        console.log(err);
        return { error: false, id: -1 };;
    }
}

export async function deshabilitarInputFormularioChecklist(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/formulario-checklist/deshabilitar-formulario-checklist/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("deshabilitarInputFormularioChecklist err");
        console.log(err);
        return false;
    }
}

export async function habilitarInputFormularioChecklist(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/formulario-checklist/habilitar-formulario-checklist/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("habilitarInputFormularioChecklist err");
        console.log(err);
        return false;
    }
}

export async function intercambiarPosicionFormularioChecklist(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/formulario-checklist/intercambiar-posicion-formulario-checklist/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("intercambiarPosicionFormularioChecklist err");
        console.log(err);
        return false;
    }
}