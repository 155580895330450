import React from 'react';

const SwitchComponent = ({ isOn, handleToggle }) => {
    return (
        <div
            className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isOn ? 'bg-green-400' : 'bg-gray-300'}`}
            onClick={handleToggle}
        >
            <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${isOn ? 'translate-x-6' : ''}`}
            />
        </div>
    );
};

export default SwitchComponent;
