import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cargandoCard from "../../components/cargando-card";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_INPUT_GENERICO, ESTILO_SECCION_BOTON_DOWN } from "../../utils/const/estilos-const";
import { deshabilitarInputFormularioChecklist, guardarInputFormularioChecklist, habilitarInputFormularioChecklist, intercambiarPosicionFormularioChecklist, obtenerFormularioChecklist } from "../../api/formulario-checklist";
import Select from "react-select";
import { SELECT_FORMULARIO_CHECKLIST } from "./const-select-formulario-checklist";
import { ArrowDownCircle, ArrowUpCircle } from "feather-icons-react/build/IconComponents";
import SwitchComponent from "../../components/switch";

export default function FormularioChecklistScreen() {
    const history = useHistory();
    const location = useLocation();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [inputFormularioSelected, setInputFormularioSelected] = useState(null);
    const [listaInputs, setListaInputs] = useState([]);
    const [idChecklist, setIdChecklist] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            // console.log("data");
            // console.log(data);
            setIdChecklist(data.id);
            let objEnviar = {};
            objEnviar.idChecklist = data.id;
            const infoFormularioChecklist = await obtenerFormularioChecklist(objEnviar);
            // console.log("infoFormularioChecklist");
            // console.log(infoFormularioChecklist);
            if (infoFormularioChecklist.length > 0) {
                let lista = [];
                for (let i = 0; i < infoFormularioChecklist.length; i++) {
                    const element = infoFormularioChecklist[i];
                    let obj = {
                        id: element.id,
                        idChecklist: data.id,
                        idConcepto: element.idConcepto,
                        value: element.idConcepto,
                        label: element.concepto,
                        habilitado: element.habilitado,
                        orden: element.orden,
                    };
                    lista.push(obj);
                }
                setListaInputs(lista);
            }
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
        }
    }

    const handleAtrasClick = () => {
        history.goBack();
    }

    const handleAgregarClick = async () => {
        console.log('handleAgregarClick');
        if (inputFormularioSelected === null) {
            return;
        }
        // console.log('inputFormularioSelected');
        // console.log(inputFormularioSelected);
        const existe = listaInputs.find(e => e.value === inputFormularioSelected.value);
        // console.log('existe');
        // console.log(existe);
        if (existe === undefined) {
            let newInput = {
                idChecklist: idChecklist,
                idConcepto: inputFormularioSelected.value,
                value: inputFormularioSelected.value,
                label: inputFormularioSelected.label,
                habilitado: true,
                orden: listaInputs.length,
            };
            const guardoNuevo = await guardarInputFormularioChecklist(newInput);
            console.log('guardoNuevo');
            console.log(guardoNuevo);
            if (guardoNuevo.error === false) {
                newInput.id = guardoNuevo.id;
                setListaInputs([...listaInputs, newInput]);
            }

        }
        if (existe !== undefined) {
            alert('Este tipo de input ya existe');
        }
    }

    const renderListInputs = () => {
        try {
            // console.log('renderListInputs');
            // console.log(listaInputs);
            return (
                <>
                    {listaInputs.map((input, index) => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        width: '40%'
                                    }}
                                >
                                    <li key={input.id}>
                                        TIPO: {input.label}
                                    </li>
                                    <input
                                        type="text"
                                        className={ESTILO_INPUT_GENERICO}
                                        maxLength={255}
                                        placeholder="EJEMPLO"
                                        readOnly
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '10%'
                                    }}
                                >
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '30%',
                                        alignItems: 'center',
                                    }}
                                >
                                    {index > 0 && <button
                                        type="button"
                                        className={ESTILO_SECCION_BOTON_DOWN}
                                        onClick={() => { moverSeccionUpClick(index); }}
                                        title="MOVER IMPUT ARRIBA"
                                    >
                                        <ArrowUpCircle />
                                    </button>}

                                    {index < listaInputs.length - 1 &&
                                        <button
                                            type="button"
                                            className={ESTILO_SECCION_BOTON_DOWN}
                                            onClick={() => { moverSeccionDownClick(index); }}
                                            title="MOVER IMPUT ABAJO"
                                        >
                                            <ArrowDownCircle />
                                        </button>}

                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '20%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <SwitchComponent isOn={input.habilitado} handleToggle={() => { handleToggle(input, index); }} />
                                    <p className="ml-4" >{input.habilitado ? "ACTIVO" : "NO ACTIVO"}</p>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <br />
                        </>
                    ))}
                </>
            );
        } catch (err) {
            console.log('render err renderListInputs');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    const handleToggle = async (input, index) => {
        try {
            // console.log('handleToggle');
            // console.log(input);
            // console.log(index);
            if (input.habilitado === 1 || input.habilitado === true) {
                const deshabilitado = await deshabilitarInputFormularioChecklist(input);
                if (deshabilitado === true) {
                    alert('Se deshabilito correctamente');
                    reset();
                }
            }
            if (input.habilitado === 0 || input.habilitado === false) {
                const habilito = await habilitarInputFormularioChecklist(input);
                if (habilito === true) {
                    alert('Se habilito correctamente');
                    reset();
                }
            }
        } catch (err) {
            console.log('handleToggle err');
            console.log(err);
        }
    }
    const moverSeccionUpClick = async (index) => {
        // console.log('moverSeccionUpClick');
        // console.log(index);
        if (index === 0) return; // No mover si es el primer elemento        
        let objEnviar = {};
        objEnviar.objetoA = listaInputs[index];
        objEnviar.objetoB = listaInputs[index - 1];
        const intercambiar = await intercambiarPosicionFormularioChecklist(objEnviar);
        if (intercambiar === true) {
            reset();
        }
        if (intercambiar === false) {
            alert('No se pudo intercambiar');
        }
    }
    const moverSeccionDownClick = async (index) => {
        // console.log('moverSeccionDownClick');
        // console.log(index);
        if (index === listaInputs.length - 1) return; // No mover si es el último elemento
        let objEnviar = {};
        objEnviar.objetoA = listaInputs[index];
        objEnviar.objetoB = listaInputs[index + 1];
        const intercambiar = await intercambiarPosicionFormularioChecklist(objEnviar);
        if (intercambiar === true) {
            reset();
        }
        if (intercambiar === false) {
            alert('No se pudo intercambiar');
        }
    }

    const reset = () => {
        setCargandoPage(true);
        iniciar();
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            return (
                <div className="pt-4 px-4 " >
                    <div className="flex justify-between">
                        <div className="flex justify-center align-center">
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                FORMULARIO CHECKLIST
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                            >
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            <label className="font-bold">SELECCIONE INPUT</label>
                            <div
                                className="flex"
                            >
                                <Select
                                    options={SELECT_FORMULARIO_CHECKLIST}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={inputFormularioSelected}
                                    onChange={(e) => {
                                        setInputFormularioSelected(e);
                                    }}
                                    placeholder={"Seleccione Input"}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            width: '350px' // Set your desired width here
                                        })
                                    }}
                                />
                                <div
                                    className={ESTILO_BOTON_GENERAL_ACEPTAR}
                                    style={{ marginRight: '16px' }}
                                >
                                    <button
                                        type="button"
                                        onClick={handleAgregarClick}
                                    >
                                        AGREGAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    {renderListInputs()}
                </div>
            );
        } catch (err) {
            console.log('render err ChecklistScreen');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}