const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getHeaders = () => {
  const bearer = localStorage.getItem("accessTokenChecklist");
  if (typeof bearer !== "string") throw new Error("missing bearer token");
  return {
    ...HEADERS,
    Authorization: `Bearer ${bearer}`,
  };
};