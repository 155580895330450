import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/grid";
import { deshabilitarIcono, obtenerListaIconos } from "../../../api/gestionar-imagenes";
import ModalConfirmar from "../../../components/modal-confirmar";
import "../../../style/style-cargando.css";
// import {
//   INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
// } from "../../../utils/const-int";
// import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
// import { TRUE } from "../../../utils/const-bool";
import cargandoCard from "../../../components/cargando-card";
import sinAccesoCard from "../../../components/sin-acceso-card";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const/estilos-const";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';

export default function ListarIconos() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "CODIGO", visible: false, },
    { key: "descripcion", title: "NOMBRE", filter: true },
    { key: "url", title: "URL", filter: true, esUrl: true, },
    { key: "fechaProceso", title: "FECHA PROCESO", filter: true },
  ];
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaIconos, setListaIconos] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [iconoSelected, setIconoSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      /*let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_GESTIONAR_ICONOS;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      console.log('permiso');
      console.log(permiso);*/
      let permiso = null;
      let listarIconos = [];
      /*if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);*/
      listarIconos = await obtenerListaIconos();
      /* }
       if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
         if (permiso.accesoAlModulo === TRUE) {
           setTieneAccesoAlModulo(true);
           listarIconos = await obtenerListaIconos();
         }
       }*/
      agregarAccionesALista(listarIconos, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaIconos(lista);
      }
      // if (lista.length > 0) {
      let objDeshabilitar = {
        label: "Deshabilitar",
        color: "red",
        onClick: (param) => cambiarEstado(param),
      };
      let objVer = {
        label: "Ver",
        color: "green",
        onClick: (param) => verIcono(param),
      };
      // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      //   if (permiso.nuevo === TRUE) {
      accesoBotonNuevo = '';
      //   }
      // }
      for (let i = 0; i < lista.length; i++) {
        let acciones = [];
        /*if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {*/
        acciones.push(objDeshabilitar);
        acciones.push(objVer);
        accesoBotonNuevo = '';
        /* }
         if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
           if (permiso.eliminarDeshabilitar === TRUE) {
             acciones.push(objDeshabilitar);
           }
           if (permiso.ver === TRUE) {
             acciones.push(objVer);
           }
         }*/
        lista[i].acciones = acciones;
      }
      setListaIconos(lista);
      // }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const verIcono = (data) => {
    try {
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      // console.log("verIcono");
      // console.log(data);
      window.open(data.url, '_blank');
    } catch (err) {
      console.log("verIcono err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setIconoSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.nombre = "";
    obj.titulo = "AGREGAR ICONO";
    obj.params = data;
    nextPage("icono", obj);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const reset = async () => {
    setCargandoPage(true);
    let listarIconos = await obtenerListaIconos();
    // console.log("listarIconos");
    // console.log(listarIconos);
    agregarAccionesALista(listarIconos, permisos);
    setCargandoPage(false);
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (iconoSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = iconoSelected.id;
    if (iconoSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarIcono(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div className="pt-4">
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR ICONOS
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <Grid rows={listaIconos} headers={headers} />
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar icono?"
          />
        </div>
      );
    } catch (err) {
      console.log('ListarIconos render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
