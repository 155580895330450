import { SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function crearPreguntaSeccion(obj) {
    try {
        let creado = { creado: false };
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/preguntas-seccion/crear-preguntas-seccion/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        console.log('body');
        console.log(body);
        if (code === SUCCESS) {
            creado.creado = true;
            creado.id = body.id;
        }
        return creado;
    } catch (err) {
        console.log("crearPreguntaSeccion err");
        console.log(err);
        return { creado: false };
    }
}

export async function modificarCiudad(obj) {
    try {
        let modificado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/ciudad/modificar-ciudad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        return modificado;
    } catch (err) {
        console.log("modificarCiudad err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarCiudad(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/ciudad/deshabilitar-ciudad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarCiudad err");
        console.log(err);
        return false;
    }
}
