import moment from "moment/moment";
import { POST, PUT, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaUsuariosMovil(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-movil/obtener-lista-usuario-movil/",
      {
        headers: getHeaders(),
        method: POST,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      for (let i = 0; i < body.length; i++) {
        body[i].fechaProceso = moment(body[i].fechaProceso).format(
          "DD/MM/YYYY HH:mm"
        );
        rows.push(body[i]);
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerListaUsuariosMovil err");
    console.log(err);
    return [];
  }
}

export async function crearUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-movil/guardar-usuario-movil/",
      {
        headers: getHeaders(),
        method: POST,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("crearUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function modificarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-movil/modificar-usuario-movil/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function deshabilitarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-movil/deshabilitar-usuario-movil/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioMovil err");
    console.log(err);
    return false;
  }
}

export async function habilitarUsuarioMovil(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-movil/habilitar-usuario-movil/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioMovil err");
    console.log(err);
    return false;
  }
}