export const OBJECT = "object";
export const ACCIONES = "acciones";
export const CORREOS = "correos";
export const GEOCERCAS = "geocercas";
export const COLOR = "color";
export const COLOR_A_TIEMPO = "coloratiempo";
export const COLOR_RETRASO = "colorretraso";
export const UBICACION_GPS = "ubicaciongps";
export const CHECK = "check";
export const URL = "url";


export const STRING_DEFAULT_OPCION_RESPUESTA_COLOR = "#d43224";

export const STRING_SIDEBAR_EXPANDED = "SIDEBAR_EXPANDED";
export const STRING_TRUE = "true";
export const STRING_FALSE = "false";

export const STRING_DEFAULT_URL_IMAGEN_PREGUNTA_CHECKLIST = "";