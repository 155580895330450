import { useState, useEffect } from "react";
import { obtenerListaTipoChecklist } from "../api/tipo-checklist";
import { obtenerListaArea } from "../api/area";
import { obtenerListaCiudad } from "../api/ciudad";
import { obtenerListaMarcaVehiculo } from "../api/marca-vehiculo";
import { obtenerListaTipoCombustible } from "../api/tipo-combustible";
import Select from "react-select";
import { ESTILO_BOTON_MODAL_ACEPTAR, ESTILO_BOTON_MODAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../utils/const/estilos-const";
import { obtenerListaTipoCarroceria } from "../api/tipo-carroceria";
import { obtenerListaTipoVehiculo } from "../api/tipo-vehiculo";

export default function ModalCrearChecklist({ isOpen, onCancel, onConfirm, elemento, handleInputChange }) {

    const [listaTipoChecklist, setListaTipoChecklist] = useState([]);
    const [tipoChecklistSelected, setTipoChecklistSelected] = useState(null);

    const [listaArea, setListaArea] = useState([]);
    const [areaSelected, setAreaSelected] = useState(null);

    const [listaCiudad, setListaCiudad] = useState([]);
    const [ciudadSelected, setCiudadSelected] = useState(null);

    const [listaMarcaVehiculo, setListaMarcaVehiculo] = useState([]);
    const [marcaVehiculoSelected, setMarcaVehiculoSelected] = useState(null);

    const [listaTipoCombustible, setListaTipoCombustible] = useState([]);
    const [tipoCombustibleSelected, setTipoCombustibleSelected] = useState(null);

    const [listaTipoCarroceria, setListaTipoCarroceria] = useState([]);
    const [tipoCarroceriaSelected, setTipoCarroceriaSelected] = useState(null);

    const [listaTipoVehiculo, setListaTipoVehiculo] = useState([]);
    const [tipoVehiculoSelected, setTipoVehiculoSelected] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    useEffect(() => {
        if (isOpen) {
            setTipoChecklistSelected(null);
            setAreaSelected(null);
            setCiudadSelected(null);
            setMarcaVehiculoSelected(null);
            setTipoCarroceriaSelected(null);
            setTipoCombustibleSelected(null);
        }
    }, [isOpen]);

    const iniciar = async () => {
        try {
            console.log('iniciar');
            const listaTC = await obtenerListaTipoChecklist(true);
            // console.log('listaTC');
            // console.log(listaTC);
            setListaTipoChecklist(listaTC);

            const listaA = await obtenerListaArea(true);
            // console.log('listaA');
            // console.log(listaA);
            setListaArea(listaA);

            const listaCiu = await obtenerListaCiudad(true);
            // console.log('listaCiu');
            // console.log(listaCiu);
            setListaCiudad(listaCiu);

            const listaMV = await obtenerListaMarcaVehiculo(true);
            // console.log('listaMV');
            // console.log(listaMV);
            setListaMarcaVehiculo(listaMV);

            const listaTComb = await obtenerListaTipoCombustible(true);
            // console.log('listaTComb');
            // console.log(listaTComb);
            setListaTipoCombustible(listaTComb);

            const listaTCarroC = await obtenerListaTipoCarroceria(true);
            // console.log('listaTCarroC');
            // console.log(listaTCarroC);
            setListaTipoCarroceria(listaTCarroC);

            const listaTV = await obtenerListaTipoVehiculo(true);
            // console.log('listaTV');
            // console.log(listaTV);
            setListaTipoVehiculo(listaTV);

        } catch (err) {
            console.log('iniciar err');
            console.log(err);
        }
    }

    const onPressCancelar = () => {
        setTipoChecklistSelected(null);
        setAreaSelected(null);
        setCiudadSelected(null);
        setMarcaVehiculoSelected(null);
        setTipoCarroceriaSelected(null);
        setTipoCombustibleSelected(null);
        onCancel();
    }

    const onPressCrear = () => {
        onConfirm(tipoChecklistSelected, areaSelected, ciudadSelected, marcaVehiculoSelected, tipoCarroceriaSelected, tipoCombustibleSelected, tipoVehiculoSelected);
    }

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    <div className="bg-white w-11/12 md:max-w-5xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                        <div className="pt-4 text-left px-6">
                            <h2 className="text-lg font-bold mb-4">CREAR NUEVO CHECKLIST</h2>
                        </div>
                        <div className="text-left px-6">
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className="col-span-2">
                                    <div className="flex items-center">
                                        <span className="text-red-500 font-bold">*</span>
                                        <label className="font-bold ml-1">NOMBRE CHECKLIST</label>
                                    </div>
                                    <input
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        value={elemento.nombre}
                                        onChange={handleInputChange}
                                        className={ESTILO_INPUT_GENERICO}
                                        required
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className="col-span-1">
                                    <label className="font-bold">TIPO CHECKLIST</label>
                                    <Select
                                        options={listaTipoChecklist}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={tipoChecklistSelected}
                                        onChange={(e) => {
                                            setTipoChecklistSelected(e);
                                        }}
                                        placeholder={"Seleccione Tipo Checklist"}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="font-bold">AREA</label>
                                    <Select
                                        options={listaArea}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={areaSelected}
                                        onChange={(e) => {
                                            setAreaSelected(e);
                                        }}
                                        placeholder={"Seleccione Area"}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="font-bold">CIUDAD</label>
                                    <Select
                                        options={listaCiudad}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={ciudadSelected}
                                        onChange={(e) => {
                                            setCiudadSelected(e);
                                        }}
                                        placeholder={"Seleccione Ciudad"}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="font-bold">TIPO VEHICULO</label>
                                    <Select
                                        options={listaTipoVehiculo}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={tipoVehiculoSelected}
                                        onChange={(e) => {
                                            setTipoVehiculoSelected(e);
                                        }}
                                        placeholder={"Seleccione Tipo Vehiculo"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className="col-span-1">
                                    <label className="font-bold">MARCA VEHICULO</label>
                                    <Select
                                        options={listaMarcaVehiculo}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={marcaVehiculoSelected}
                                        onChange={(e) => {
                                            setMarcaVehiculoSelected(e);
                                        }}
                                        placeholder={"Seleccione Marca Vehiculo"}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="font-bold">TIPO CARROCERIA</label>
                                    <Select
                                        options={listaTipoCarroceria}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={tipoCarroceriaSelected}
                                        onChange={(e) => {
                                            setTipoCarroceriaSelected(e);
                                        }}
                                        placeholder={"Seleccione Tipo Carroceria"}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="font-bold">TIPO COMBUSTIBLE</label>
                                    <Select
                                        options={listaTipoCombustible}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={tipoCombustibleSelected}
                                        onChange={(e) => {
                                            setTipoCombustibleSelected(e);
                                        }}
                                        placeholder={"Seleccione Tipo Combustible"}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className="col-span-2">
                                    <label className="font-bold">NOTA</label>
                                    <textarea
                                        id="nota"
                                        name="nota"
                                        type="text"
                                        value={elemento.nota}
                                        onChange={handleInputChange}
                                        className={ESTILO_INPUT_GENERICO}
                                        required
                                        maxLength={2047}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <hr />
                        <div className="py-4 text-left px-6">
                            <div className="flex justify-end">
                                <button
                                    className={ESTILO_BOTON_MODAL_CANCELAR}
                                    onClick={onPressCancelar}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className={ESTILO_BOTON_MODAL_ACEPTAR}
                                    onClick={onPressCrear}
                                >
                                    Crear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

// export default ModalCrearChecklist;