import React from 'react';
import Trash2 from 'feather-icons-react/build/IconComponents/Trash2';
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_ROTATE_45 } from '../utils/const/estilos-const';

const FooterPregunta = ({ indexSeccion, indexPregunta, handleEliminarPregunta }) => {

    return (
        <div className="flex justify-between mt-2">
            <label>PREGUNTA NRO:</label>
            <label style={{ fontWeight: "bold" }}>{indexPregunta}</label>
            <button
                type="button"
                className={`${ESTILO_BOTON_GENERAL_ACEPTAR} flex items-center`}
                onClick={() => handleEliminarPregunta(indexSeccion, indexPregunta)}
                title="ELIMINAR PREGUNTA"
            >
                <Trash2 className={`mr-2 ${ESTILO_ROTATE_45}`} />
                ELIMINAR PREGUNTA
            </button>
        </div>
    );
};

export default FooterPregunta;
