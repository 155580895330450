import { urlGestion } from "../../conexion/conexion";

export async function obtenerTodasLasUnidades() {
    try {
        let token = localStorage.getItem("accessTokenChecklist");
        let obj = {};
        obj.token = token;
        let rows = [];
        let objDatos = {
            data_op: obj,
            op: "READ-OBTENERTODASLASUNIDADES",
        };
        let data = await fetch(urlGestion, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(objDatos),
        }).then((res) => res.json());
        // console.log('data');
        // console.log(data);
        if (data.error === false && data.data !== undefined) {
            rows = data.data;
        }
        return rows;
    } catch (err) {
        console.log("obtenerTodasLasUnidades err");
        console.log(err);
        return [];
    }
}

export async function obtenerInfoConductorConNumeroPlaca(obj) {
    try {
        let token = localStorage.getItem("accessTokenChecklist");
        obj.token = token;
        let info = null;
        let objDatos = {
            data_op: obj,
            op: "READ-OBTENERINFOCONDUCTORCONNUMEROPLACA",
        };
        let data = await fetch(urlGestion, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(objDatos),
        }).then((res) => res.json());
        // console.log('data');
        // console.log(data);
        // debugger;
        if (data.error === false && data.data !== undefined) {
            info = data.data[0] !== undefined ? data.data[0] : null;
        }
        return info;
    } catch (err) {
        console.log("obtenerInfoConductorConNumeroPlaca err");
        console.log(err);
        return null;
    }
}