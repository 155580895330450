export const ESTILO_BOTON_GRILLA_NEXT =
    "inline-flex justify-center px-4 py-2 mt-[10px] ml-[8px] border border-transparent shadow-sm text-sm font-medium rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
export const ESTILO_BOTON_GRILLA_PREVIOUS =
    "inline-flex justify-center px-4 py-2 mt-[10px] ml-[8px] border border-transparent shadow-sm text-sm font-medium rounded-md text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_LISTAR_NUEVO =
    "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
export const ESTILO_BOTON_LISTAR_ATRAS =
    "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_GENERAL_ACEPTAR =
    "inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-indigo -500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_GENERAL_CANCELAR =
    "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_INPUT_GENERICO =
    "block w-full h-12 sm:text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 hover:border-indigo-700 transition duration-300 ease-in-out pl-2";

export const ESTILO_BOTON_MODAL_CANCELAR = "mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500";

export const ESTILO_BOTON_MODAL_ACEPTAR = "inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blueBoltrack2 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500";

export const ESTILO_ROTATE_180 = "transition-transform duration-500 ease-in-out hover:rotate-180"

export const ESTILO_ROTATE_45 = "transition-transform duration-500 ease-in-out hover:rotate-45"

export const ESTILO_BOTON_UP =
    "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_BOTON_DOWN =
    "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_SECCION_BOTON_UP =
    "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

export const ESTILO_SECCION_BOTON_DOWN =
    "inline-flex justify-center px-4 py-2 mr-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

/** */

