export const sharedNextPage = (page, params, history) => {
    try {
        // const history = useHistory();
        // history.push(`${page}`);
        // console.log('params');
        // console.log(params);
        if (params !== undefined && params !== null) {
            if (params.acciones !== undefined) {
                delete params.acciones;
            }
            // console.log('params sin acciones');
            // console.log(params);
            history.push({
                pathname: `${page}`,
                state: params
            });
        } else {
            history.push(`${page}`);
        }
    } catch (err) {
        console.log('nextPage err');
        console.log(err);
    }
}; 