import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ConfiguracionMain from ".";
// import LayoutConfiguracion from "../../components/layout-configuracion";
import ListarOpcionesRespuesta from "./opciones-respuesta/listar-opciones-respuesta";
import OpcionesRespuesta from "./opciones-respuesta/opciones-respuesta";
import ListarCorreos from "./correos/listar-correos";
import Correo from "./correos/correo";
import ListarTipoChecklist from "./tipo-checklist/listar-tipo-checklist";
import TipoCheckList from "./tipo-checklist/tipo-checklist";
import ListarIconos from "./gestionar-iconos/listar-iconos";
import Icono from "./gestionar-iconos/icono";
import ListarArea from "./gestionar-area/listar-area";
import Area from "./gestionar-area/area";
import ListarCiudad from "./gestionar-ciudad/listar-ciudad";
import Ciudad from "./gestionar-ciudad/ciudad";
import ListarMarcaVehiculo from "./gestionar-marca-vehiculo/listar-marca-vehiculo";
import MarcaVehiculo from "./gestionar-marca-vehiculo/marca-vehiculo";
import ListarTipoCombustible from "./gestionar-tipo-combustible/listar-tipo-combustible";
import TipoCombustible from "./gestionar-tipo-combustible/tipo-combustible";
import ListarTipoCarroceria from "./gestionar-tipo-carroceria/listar-tipo-carroceria";
import TipoCarroceria from "./gestionar-tipo-carroceria/tipo-carroceria";
import ListarTipoVehiculo from "./gestionar-tipo-vehiculo/listar-marca-vehiculo";
import TipoVehiculo from "./gestionar-tipo-vehiculo/marca-vehiculo";
import ListarTipoUsuarioMovil from "./gestionar-tipo-usuario-movil/listar-tipo-usuario-movil";
import TipoUsuarioMovil from "./gestionar-tipo-usuario-movil/tipo-usuario-movil";
import ListarSubTipoUsuarioWeb from "./gestionar-sub-tipo-usuario-web/listar-sub-tipo-usuario-web";
import SubTipoUsuarioWeb from "./gestionar-sub-tipo-usuario-web/sub-tipo-usuario-web";

const RouterConfiguracion = () => {
  const { path } = useRouteMatch();
  return (
    // <LayoutConfiguracion>
    <Switch>

      <Route exact path={`${path}/opciones-respuesta/listar-opciones-respuesta`}>
        <ListarOpcionesRespuesta />
      </Route>
      <Route exact path={`${path}/opciones-respuesta/opciones-respuesta`}>
        <OpcionesRespuesta />
      </Route>

      <Route exact path={`${path}/correos/listar-correos`}>
        <ListarCorreos />
      </Route>
      <Route exact path={`${path}/correos/correo`}>
        <Correo />
      </Route>

      <Route exact path={`${path}/tipo-checklist/listar-tipo-checklist`}>
        <ListarTipoChecklist />
      </Route>
      <Route exact path={`${path}/tipo-checklist/tipo-checklist`}>
        <TipoCheckList />
      </Route>

      <Route exact path={`${path}/gestionar-iconos/listar-iconos`}>
        <ListarIconos />
      </Route>
      <Route exact path={`${path}/gestionar-iconos/icono`}>
        <Icono />
      </Route>

      <Route exact path={`${path}/gestionar-area/listar-area`}>
        <ListarArea />
      </Route>
      <Route exact path={`${path}/gestionar-area/area`}>
        <Area />
      </Route>

      <Route exact path={`${path}/gestionar-ciudad/listar-ciudad`}>
        <ListarCiudad />
      </Route>
      <Route exact path={`${path}/gestionar-ciudad/ciudad`}>
        <Ciudad />
      </Route>

      <Route exact path={`${path}/gestionar-marca-vehiculo/listar-marca-vehiculo`}>
        <ListarMarcaVehiculo />
      </Route>
      <Route exact path={`${path}/gestionar-marca-vehiculo/marca-vehiculo`}>
        <MarcaVehiculo />
      </Route>

      <Route exact path={`${path}/gestionar-tipo-combustible/listar-tipo-combustible`}>
        <ListarTipoCombustible />
      </Route>
      <Route exact path={`${path}/gestionar-tipo-combustible/tipo-combustible`}>
        <TipoCombustible />
      </Route>

      <Route exact path={`${path}/gestionar-tipo-carroceria/listar-tipo-carroceria`}>
        <ListarTipoCarroceria />
      </Route>
      <Route exact path={`${path}/gestionar-tipo-carroceria/tipo-carroceria`}>
        <TipoCarroceria />
      </Route>

      <Route exact path={`${path}`}>
        <ConfiguracionMain />
      </Route>

      <Route exact path={`${path}/gestionar-tipo-vehiculo/listar-tipo-vehiculo`}>
        <ListarTipoVehiculo />
      </Route>
      <Route exact path={`${path}/gestionar-tipo-vehiculo/tipo-vehiculo`}>
        <TipoVehiculo />
      </Route>

      <Route exact path={`${path}/gestionar-tipo-usuario-movil/listar-tipo-usuario-movil`}>
        <ListarTipoUsuarioMovil />
      </Route>
      <Route exact path={`${path}/gestionar-tipo-usuario-movil/tipo-usuario-movil`}>
        <TipoUsuarioMovil />
      </Route>

      <Route exact path={`${path}/gestionar-sub-tipo-usuario-web/listar-sub-tipo-usuario-web`}>
        <ListarSubTipoUsuarioWeb />
      </Route>
      <Route exact path={`${path}/gestionar-sub-tipo-usuario-web/sub-tipo-usuario-web`}>
        <SubTipoUsuarioWeb />
      </Route>

    </Switch>
    // </LayoutConfiguracion>
  );
};

export default RouterConfiguracion;
