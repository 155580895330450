import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const/estilos-const";
import { crearUsuarioMovil, modificarUsuarioMovil, obtenerListaUsuariosMovil, } from "../../api/usuario";
import Select from "react-select";
import { obtenerInfoConductorConNumeroPlaca, obtenerTodasLasUnidades } from "../../api/gestion-dts/gestion";
// import { INT_TIPO_USUARIO_SUPERVISOR_MOVIL } from "../../utils/const/int-const";
import "../../style/style-cargando.css";
import { obtenerListaTipoUsuarioMovil } from "../../api/tipo-usuario-movil";
import cargandoCard from "../../components/cargando-card";
import sinDataCard from "../../components/sin-data-card";

export default function UsuarioMovil() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [titulo, setTitulo] = useState("CREAR USUARIO MOVIL");
  const [listaUnidades, setListaUnidades] = useState([]);
  const [unidadSelected, setUnidadSelected] = useState(null);
  const [listaTipoUsuarioMovil, setListaTipoUsuarioMovil] = useState([]);
  const [tipoUsuarioMovilSelected, setTipoUsuarioMovilSelected] = useState(null);

  useEffect(() => {
    console.log("entro a UsuarioMovil");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setUsuario(data.params);
        setTitulo(data.titulo);

        let tiposUsuarioMovil = await obtenerListaTipoUsuarioMovil(true)
        // console.log('tiposUsuarioMovil');
        // console.log(tiposUsuarioMovil);
        setListaTipoUsuarioMovil(tiposUsuarioMovil);
        if (data.params.idTipoUsuarioMovil !== null) {
          const tipoUsuario = tiposUsuarioMovil.find(e => e.value === data.params.idTipoUsuarioMovil);
          if (tipoUsuario !== undefined) {
            setTipoUsuarioMovilSelected(tipoUsuario);
          }
        }

        let todasLasUnidades = await obtenerTodasLasUnidades();
        // console.log('todasLasUnidades');
        // console.log(todasLasUnidades);
        if (data.params.valueUnidad !== null) {
          const unidad = todasLasUnidades.find(e => e.value === data.params.valueUnidad);
          if (unidad !== undefined) {
            setUnidadSelected(unidad);
          }
        }
        let listaUsuarioMovil = await obtenerListaUsuariosMovil();
        // console.log('listaUsuarioMovil');
        // console.log(listaUsuarioMovil);
        const unidadesFiltradas = todasLasUnidades.filter(objA => !listaUsuarioMovil.some(objB => objB.unidad === objA.value));
        setListaUnidades(unidadesFiltradas);

      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setUsuario({ ...usuario, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    try {
      // console.log('handleAceptarClick');
      // lógica para guardar los cambios
      // console.log('usuario');
      // console.log(usuario);
      let valido = await validarUsuario();
      // console.log('valido');
      // console.log(valido);
      if (valido === false) {
        return;
      }
      let objEnviar = {};
      objEnviar.id = usuario.id;
      objEnviar.nombre = usuario.nombre;
      objEnviar.apellidoPaterno = usuario.apellidoPaterno;
      objEnviar.apellidoMaterno = usuario.apellidoMaterno;
      objEnviar.numeroLicConducir = usuario.numeroLicConducir;
      objEnviar.telefono = usuario.telefono;
      objEnviar.usuario = usuario.usuario;
      objEnviar.contrasena = usuario.contrasena;
      objEnviar.nota = usuario.nota;
      objEnviar.valueUnidad = "";
      objEnviar.labelUnidad = "";
      if (unidadSelected !== null) {
        objEnviar.valueUnidad = unidadSelected.value;
        objEnviar.labelUnidad = unidadSelected.label;
      }
      objEnviar.idTipoUsuarioMovil = "";
      if (tipoUsuarioMovilSelected !== null) {
        objEnviar.idTipoUsuarioMovil = tipoUsuarioMovilSelected.value;
      }
      if (usuario.id <= 0) {
        // console.log('crear correo');
        const creado = await crearUsuarioMovil(objEnviar);
        // console.log('creado');
        // console.log(creado);
        if (creado === true) {
          alert('Se creo correctamente');
          history.goBack();
        }
        if (creado === false) {
          alert('No se pudo crear');
        }
      }
      if (usuario.id > 0) {
        // console.log('actualizar correo');
        const modificado = await modificarUsuarioMovil(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          history.goBack();
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
    } catch (err) {
      console.log('handleAceptarClick err');
      console.log(err);
      alert('No se pudo continuar, por favor revise los campos');
    }
  };

  const validarUsuario = async () => {
    try {
      let error = '';
      if (usuario === undefined || usuario === null || usuario.nombre === undefined === usuario.nombre === null || String(usuario.nombre).length === 0) {
        error = error + 'Necesita ingresar nombre\n';
      }
      if (usuario.apellidoPaterno === undefined === usuario.apellidoPaterno === null || String(usuario.apellidoPaterno).length === 0) {
        error = error + 'Necesita ingresar apellido\n';
      }
      if (usuario.apellidoMaterno === undefined === usuario.apellidoMaterno === null) {
        usuario.apellidoMaterno = '';
      }
      if (usuario.usuario === undefined === usuario.usuario === null || String(usuario.usuario).length === 0) {
        error = error + 'Necesita ingresar usuario\n';
      }
      if (usuario.contrasena === undefined === usuario.contrasena === null || String(usuario.contrasena).length === 0) {
        error = error + 'Necesita ingresar contraseña\n';
      }
      if (usuario.nota === undefined || usuario.nota === null) {
        usuario.nota = '';
      }
      if (usuario.telefono === undefined || usuario.telefono === null) {
        usuario.telefono = '';
      }
      if (String(error).length === 0) {
        return true;
      }
      if (String(error).length > 0) {
        alert(error);
      }
      return false;
    } catch (err) {
      console.log('validarUsuario err');
      console.log(err);
      return false;
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleUnidadSelected = async (event) => {
    try {
      if (event !== null) {
        // console.log('event');
        // console.log(event);
        let obj = {};
        obj.idActivo = event.value;
        let infoConductor = await obtenerInfoConductorConNumeroPlaca(obj);
        // console.log('infoConductor');
        // console.log(infoConductor);
        if (infoConductor !== null) {
          setUsuario(prevUsuario => ({
            ...prevUsuario,
            nombre: infoConductor.nombre || "",
            apellidoPaterno: infoConductor.apellidoPaterno || "",
            apellidoMaterno: infoConductor.apellidoMaterno || "",
            numeroLicConducir: infoConductor.nroLicConducir || "",
            telefono: infoConductor.nroCelPersonal || ""
          }));
        }
        if (infoConductor === null) {
          resetInfoUsuario();
        }

      }
      setUnidadSelected(event);
    } catch (err) {
      console.log('handleUnidadSelected err');
      console.log(err);
    }
  }

  const handleTipoUsuarioMovilSelected = (event) => {
    try {
      setTipoUsuarioMovilSelected(event);
    } catch (err) {
      console.log('handleTipoUsuarioMovilSelected err');
      console.log(err);
    }
  }

  const resetInfoUsuario = () => {
    const camposAResetear = [
      'nombre',
      'apellidoPaterno',
      'apellidoMaterno',
      'numeroLicConducir',
      'telefono'
    ];

    setUsuario(prevUsuario =>
      camposAResetear.reduce((nuevoUsuario, campo) => {
        nuevoUsuario[campo] = '';
        return nuevoUsuario;
      }, { ...prevUsuario })
    );
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && usuario === null) {
        return sinDataCard();
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  TIPO USUARIO MOVIL
                </label>
                <Select options={listaTipoUsuarioMovil} isSearchable='true'
                  isClearable='true'
                  value={tipoUsuarioMovilSelected}
                  onChange={(event) => handleTipoUsuarioMovilSelected(event)}
                  placeholder='SELECCIONE TIPO USUARIO'
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  UNIDAD
                </label>
                <Select options={listaUnidades} isSearchable='true'
                  isClearable='true'
                  value={unidadSelected}
                  onChange={(event) => handleUnidadSelected(event)}
                  placeholder='SELECCIONE UNIDAD'
                />
              </div>
            </div>

          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombre" className="mb-2 font-bold">
                  NOMBRE
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={usuario.nombre}
                  onChange={handleInputChange}
                  placeholder="NOMBRE"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellidoPaterno" className="mb-2 font-bold">
                  APELLIDO PATERNO
                </label>
                <input
                  type="text"
                  id="apellidoPaterno"
                  name="apellidoPaterno"
                  value={usuario.apellidoPaterno}
                  onChange={handleInputChange}
                  placeholder="APELLIDO PATERNO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellidoMaterno" className="mb-2 font-bold">
                  APELLIDO MATERNO
                </label>
                <input
                  type="text"
                  id="apellidoMaterno"
                  name="apellidoMaterno"
                  value={usuario.apellidoMaterno}
                  onChange={handleInputChange}
                  placeholder="APELLIDO MATERNO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="numeroLicConducir" className="mb-2 font-bold">
                  NUM. LIC. CONDUCIR
                </label>
                <input
                  type="text"
                  id="numeroLicConducir"
                  name="numeroLicConducir"
                  value={usuario.numeroLicConducir}
                  onChange={handleInputChange}
                  placeholder="LIC. CONDUCIR"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="telefono" className="mb-2 font-bold">
                  TELEFONO
                </label>
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={usuario.telefono}
                  onChange={handleInputChange}
                  placeholder="TELEFONO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">

              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  USUARIO
                </label>
                <input
                  type="text"
                  id="usuario"
                  name="usuario"
                  value={usuario.usuario}
                  onChange={handleInputChange}
                  placeholder="USUARIO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="contrasena" className="mb-2 font-bold">
                  CONTRASEÑA
                </label>
                <input
                  type="text"
                  id="contrasena"
                  name="contrasena"
                  value={usuario.contrasena}
                  onChange={handleInputChange}
                  placeholder="CONTRASEÑA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={usuario.nota}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={512}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('UsuarioMovil render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
