/*export const tipoPregunta = [
    {
        label: "SELECCION MULTIPLE",
        value: 5,
    },
    {
        label: "IMAGEN",
        value: 6,
    },
    {
        label: "COMENTARIO",
        value: 7,
    },
    {
        label: "FIRMA",
        value: 8,
    }
];
*/
export const INT_PREGUNTA_SELECCION_SIMPLE = 9;
export const STRING_PREGUNTA_SELECCION_SIMPLE = "SELECCION SIMPLE";

export const INT_PREGUNTA_SELECCION_MULTIPLE = 5;
export const STRING_PREGUNTA_SELECCION_MULTIPLE = "SELECCION MULTIPLE";

export const INT_PREGUNTA_IMAGEN = 6;
export const STRING_PREGUNTA_IMAGEN = "IMAGEN";

export const INT_PREGUNTA_COMENTARIO = 7;
export const STRING_PREGUNTA_COMENTARIO = "COMENTARIO";

export const INT_PREGUNTA_FIRMA = 8;
export const STRING_PREGUNTA_FIRMA = "FIRMA";