import { useState, useEffect } from "react";
import Modulo from "../../components/modulo";
import { MODULOS_CONFIGURACION_GENERAL } from "./modulos-configuracion-general";
import "../../style/style-cargando.css";
import sinAccesoCard from "../../components/sin-acceso-card";
import cargandoCard from "../../components/cargando-card";

// const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));

export default function ConfiguracionMain() {

    const [permisos, setPermisos] = useState(null);
    const [cargandoPage, setCargandoPage] = useState(false);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            /*let objPermiso = {};
            objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
            objPermiso.idMenuSegundoNivel = 0;
            objPermiso.idMenuTercerNivel = 0;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
            // console.log('obtenerPermisos');
            // console.log(obtenerPermisos);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            // console.log('idTipoUsuario');
            // console.log(idTipoUsuario);
            // debugger;
            if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                setTieneAccesoAlModulo(true);
            }
            if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                if (permiso.accesoAlModulo === TRUE) {
                    setTieneAccesoAlModulo(true);
                }
            }*/
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div>
                    <div className="flex flex-wrap">
                        {MODULOS_CONFIGURACION_GENERAL.map((data) => Modulo(data))}
                    </div>
                </div>
            );
        } catch (err) {
            console.log("ConfiguracionMain render");
            console.log(err);
            return (
                <div className=" w-1/2 m-auto px-[2rem] ">
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    };

    return render();
}
