import { useState } from "react";
import { useHistory } from "react-router-dom";
import { verifyLogin } from "../../api/autenticacion";
import "./Login.css";
import spinner from "./spinner4.svg";
import logo from "./logo.png";

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  // const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const submit = async (e) => {
    try {
      // console.log('submit');
      e.preventDefault();
      setLoading(true);
      const response = await verifyLogin(usuario, contrasenia);
      console.log(response);
      if (response !== null) {
        localStorage.setItem("accessTokenChecklist", response.token);
        localStorage.setItem("tipoUsuarioChecklist", response.idTipoUsuarioWeb);
        localStorage.setItem("idEmpresaChecklist", response.idEmpresa);
        history.push(`/`);
      } else {
        if (response === null) {
          alert('Usuario o contrasena incorrecto');
          return;
        }
        if (response !== null) {
          alert(response.message);
          return;
        }
      }
    } catch (err) {
      console.log('submit err');
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="fs f">
          <div className="login_box">
            <img id="logo" src={logo} alt="Logo Boltrack"></img>
            <span className="title">Plataforma de Checklist</span>
            <div className="login_fields">
              <div className="field_line">
                <span className="fieldname">Usuario:</span>
                <input
                  id="usuario_login"
                  value={usuario}
                  onChange={(e) => {
                    setUsuario(e.target.value);
                  }}
                  className="login_input"
                />
              </div>
              <div className="field_line">
                <span className="fieldname">Contraseña:</span>
                <input
                  id="password_login"
                  value={contrasenia}
                  onChange={(e) => {
                    setContrasenia(e.target.value);
                  }}
                  className="login_input"
                  type="password"
                />
              </div>
              <div className="field_line">
                {loading === false &&
                  <button type="onSubmit" className="login_button">
                    Ingresar
                  </button>
                }
                {loading === true &&
                  <button className="login_button flex justify-center">
                    <img className="spinner" alt="spinner" src={spinner} />
                  </button>}
              </div>
              <div>
                <label>version 1.0.0</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
