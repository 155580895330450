import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import noContentCard from "../../components/no-content-card";
import { useHistory, useLocation } from "react-router-dom";
import {
    ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_MODAL_ACEPTAR,
    ESTILO_INPUT_GENERICO,
} from "../../utils/const/estilos-const";
import { ChevronLeft, ChevronsLeft } from "feather-icons-react/build/IconComponents";
import { obtenerDetalleRespuestaChecklist } from "../../api/checklist";
import { INT_PREGUNTA_COMENTARIO, INT_PREGUNTA_FIRMA, INT_PREGUNTA_IMAGEN, INT_PREGUNTA_SELECCION_SIMPLE } from "../../utils/const/tipo-pregunta-const";

export default function DetalleRespuestasChecklist() {

    const location = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [checklistSelected, setChecklistSelected] = useState({});
    const [noContent, setNoContent] = useState(false);
    const [listaSecciones, setListaSecciones] = useState([]);
    const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            // console.log("data");
            // console.log(data);
            setChecklistSelected(data.params);
            let objEnviar = {};
            objEnviar.idRespuestaChecklist = data.params.id;
            const detalleRespuesta = await obtenerDetalleRespuestaChecklist(objEnviar);
            // console.log('detalleRespuesta');
            // console.log(detalleRespuesta);
            setListaSecciones(detalleRespuesta);
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
            setNoContent(true);
        }
    }

    const handleAtrasClick = () => {
        history.goBack();
    }

    const renderSections = () => {
        try {
            // console.log('renderSections');
            return listaSecciones.map((section) => {
                // console.log('section');
                // console.log(section);
                return (
                    <div
                        key={section.idSection}
                    >
                        <label className="font-bold">
                            {section.nombreSeccion}
                        </label>
                        <div>
                            {renderPreguntas(section.preguntas)}
                        </div>
                    </div>
                );
            });
        } catch (err) {
            console.log("renderSections err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    };

    const renderPreguntas = (preguntas) => {
        try {
            return preguntas.map((pregunta) => {
                switch (pregunta.idTipoPregunta) {
                    case INT_PREGUNTA_SELECCION_SIMPLE:
                        return renderPreguntaSimple(pregunta);
                    case INT_PREGUNTA_IMAGEN:
                        return renderPreguntaImagen(pregunta);
                    case INT_PREGUNTA_COMENTARIO:
                        return renderPreguntaComentario(pregunta);
                    case INT_PREGUNTA_FIRMA:
                        return renderPreguntaFirma(pregunta);
                }
            });
        } catch (err) {
            console.log("renderPreguntas err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    };

    const renderPreguntaSimple = (pregunta) => {
        try {
            console.log('renderPreguntaSimple');
            console.log(pregunta);
            return (
                <div
                    key={pregunta.idPreguntaSeccion}
                    className="flex justify-between ml-4"
                    style={{ borderBottom: '1px solid grey' }}
                >
                    <div>{pregunta.labelPregunta}:</div>
                    <div>
                        <input type="checkbox" checked readonly />
                        <label>{pregunta.labelOpcionRespuesta}</label>
                        <div className="flex flex-col">
                            {pregunta.urlFotoRespuesta !== undefined || pregunta.urlFotoRespuesta !== null ||
                                pregunta.detalleNotaObservacionRespuesta !== undefined || pregunta.detalleNotaObservacionRespuesta !== null ?
                                <label className="font-bold" >DATOS EXTRA REQUERIDOS</label> : ""}
                            {pregunta.urlFotoRespuesta !== undefined && pregunta.urlFotoRespuesta !== null &&
                                <img
                                    src={pregunta.urlFotoRespuesta}
                                    width={256}
                                    height={512}
                                    alt="Image"
                                    onClick={() => window.open(pregunta.urlFotoRespuesta, '_blank')}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            {pregunta.detalleNotaObservacionRespuesta !== undefined && pregunta.detalleNotaObservacionRespuesta !== null &&
                                <label>Observacion: {pregunta.detalleNotaObservacionRespuesta}</label>
                            }
                        </div>
                    </div>
                </div>
            );
        } catch (err) {
            console.log("renderPreguntaSimple err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    }

    const renderPreguntaImagen = (pregunta) => {
        try {
            // console.log('renderPreguntaImagen');
            // console.log(pregunta);
            return (
                <div
                    key={pregunta.idPreguntaSeccion}
                    className="ml-4"
                    style={{ borderBottom: '1px solid grey' }}
                >
                    <label>{pregunta.labelPregunta}:</label>
                    <img
                        src={pregunta.urlArchivo}
                        width={256}
                        height={512}
                        alt="Image"
                        onClick={() => window.open(pregunta.urlArchivo, '_blank')}
                        style={{ cursor: 'pointer' }}
                    />

                </div>
            );
        } catch (err) {
            console.log("renderPreguntaImagen err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    }

    const renderPreguntaFirma = (pregunta) => {
        try {
            // console.log('renderPreguntaFirma');
            // console.log(pregunta);
            return (
                <div
                    key={pregunta.idPreguntaSeccion}
                    className="ml-4"
                    style={{ borderBottom: '1px solid grey' }}
                >
                    <label>{pregunta.labelPregunta}:</label>
                    <img
                        src={pregunta.urlArchivo}
                        width={256}
                        height={512}
                        alt="Image"
                        onClick={() => window.open(pregunta.urlArchivo, '_blank')}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            );
        } catch (err) {
            console.log("renderPreguntaFirma err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    }

    const renderPreguntaComentario = (pregunta) => {
        try {
            // console.log('renderPreguntaComentario');
            // console.log(pregunta);
            return (
                <div
                    key={pregunta.idPreguntaSeccion}
                    className="flex justify-between ml-4"
                    style={{ borderBottom: '1px solid grey' }}
                >
                    <label >{pregunta.labelPregunta}:</label>
                    <label>{pregunta.respuesta}</label>
                </div>
            );
        } catch (err) {
            console.log("renderPreguntaComentario err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (noContent === true) {
                return (
                    <div className="flex justify-between pl-4 pr-4 items-center">
                        {noContentCard()}
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleAtrasClick}
                        >
                            ATRAS
                        </button>
                    </div>
                );
            }
            return (
                <div className="pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <div className="flex justify-center align-center">
                            <span>
                                RESPUESTA CHECKLIST:
                            </span>
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                {checklistSelected.descripcion}
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                                onMouseEnter={() => setIsHoveredBackButton(true)}
                                onMouseLeave={() => setIsHoveredBackButton(false)}
                            >
                                {isHoveredBackButton ? (
                                    <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                                ) : (
                                    <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                                )}
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <div className="flex items-center">
                                <span className="text-red-500 font-bold">*</span>
                                <label className="font-bold ml-1">NOMBRE CHECKLIST</label>
                            </div>
                            <input
                                id="nombreChecklist"
                                name="nombreChecklist"
                                type="text"
                                value={checklistSelected.nombreChecklist}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO CHECKLIST</label>
                            <input
                                id="tipoChecklist"
                                name="tipoChecklist"
                                type="text"
                                value={checklistSelected.tipoChecklist}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">AREA</label>
                            <input
                                id="nombreArea"
                                name="nombreArea"
                                type="text"
                                value={checklistSelected.nombreArea}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">CIUDAD</label>
                            <input
                                id="nombreCiudad"
                                name="nombreCiudad"
                                type="text"
                                value={checklistSelected.nombreCiudad}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO VEHICULO</label>
                            <input
                                id="tipoVehiculo"
                                name="tipoVehiculo"
                                type="text"
                                value={checklistSelected.tipoVehiculo}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">MARCA VEHICULO</label>
                            <input
                                id="marcaVehiculo"
                                name="marcaVehiculo"
                                type="text"
                                value={checklistSelected.marcaVehiculo}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO CARROCERIA</label>
                            <input
                                id="tipoCarroceria"
                                name="tipoCarroceria"
                                type="text"
                                value={checklistSelected.tipoCarroceria}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO COMBUSTIBLE</label>
                            <input
                                id="tipoCombustible"
                                name="tipoCombustible"
                                type="text"
                                value={checklistSelected.tipoCombustible}

                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                                readOnly
                            />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div>
                        {renderSections()}
                    </div>
                    <div className="flex flex-row justify-end mb-8 mt-8">
                        <button
                            className={`${ESTILO_BOTON_MODAL_ACEPTAR} flex items-center`}
                            onClick={() => { handleAtrasClick(); }}
                            title=""
                        >
                            ATRAS
                        </button>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}
/*
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-6 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-6 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-6 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-6 bg-gray-500 p-4">Column 4</div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-500 p-4">Column 4</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-600 p-4">Column 5</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-700 p-4">Column 6</div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-500 p-4">Column 4</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-600 p-4">Column 5</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-700 p-4">Column 6</div>
                    </div>
                    */