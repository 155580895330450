import "../style/style-cargando.css";

const cargandoCard = () => {
    return (
        <div className="flex flex-col items-center mt-8">
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
                <p>Cargando...</p>
            </div>
        </div>
    );
}

export default cargandoCard;