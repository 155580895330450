import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AdministracionUsuario from "./index";

import LayoutUsuario from "../../components/layout-usuario";
import ListarUsuarioMovil from "./listar-usuario-movil";
import UsuarioMovil from "./usuario-movil";
import UsuarioWeb from "./usuario-web";
import ListarUsuarioWeb from "./listar-usuario-web";

const RouterUsuarios = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutUsuario>
      <Switch>

        <Route exact path={`${path}`}>
          <AdministracionUsuario />
        </Route>

        <Route path={`${path}/listar-usuarios-web`}>
          <ListarUsuarioWeb />
        </Route>
        <Route path={`${path}/listar-usuarios-movil`}>
          <ListarUsuarioMovil />
        </Route>
        <Route path={`${path}/usuario-web`}>
          <UsuarioWeb />
        </Route>
        <Route path={`${path}/usuario-movil`}>
          <UsuarioMovil />
        </Route>

      </Switch>
    </LayoutUsuario>
  );
};

export default RouterUsuarios;
