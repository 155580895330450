import carIcon from "../../assets/iconos/car.png"

export const MODULOS_CONFIGURACION_GENERAL = [
    {
        id: 1,
        titulo: 'Gestionar Opciones Respuesta',
        descripcion: 'Opciones Respuesta',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/opciones-respuesta/listar-opciones-respuesta',
    },
    {
        id: 2,
        titulo: 'Gestionar Correos',
        descripcion: 'Correos',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/correos/listar-correos',
    },
    {
        id: 3,
        titulo: 'Gestionar Tipo Checklist',
        descripcion: 'Tipo Checklist',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/tipo-checklist/listar-tipo-checklist',
    },
    {
        id: 4,
        titulo: 'Gestionar Iconos',
        descripcion: 'Iconos',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-iconos/listar-iconos',
    },
    {
        id: 5,
        titulo: 'Gestionar Area',
        descripcion: 'Area',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-area/listar-area',
    },
    {
        id: 6,
        titulo: 'Gestionar Ciudad',
        descripcion: 'Ciudad',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-ciudad/listar-ciudad',
    },
    {
        id: 7,
        titulo: 'Gestionar Marca Vehiculo',
        descripcion: 'Marca Vehiculo',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-marca-vehiculo/listar-marca-vehiculo',
    },
    {
        id: 8,
        titulo: 'Gestionar Tipo Combustible',
        descripcion: 'Tipo Combustible',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-tipo-combustible/listar-tipo-combustible',
    },
    {
        id: 9,
        titulo: 'Gestionar Tipo Carroceria',
        descripcion: 'Tipo Carroceria',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-tipo-carroceria/listar-tipo-carroceria',
    },
    {
        id: 10,
        titulo: 'Gestionar Tipo Vehiculo',
        descripcion: 'Tipo Vehiculo',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-tipo-vehiculo/listar-tipo-vehiculo',
    },
    {
        id: 11,
        titulo: 'Gestionar Tipo Usuario Movil',
        descripcion: 'Tipo Usuario Movil',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-tipo-usuario-movil/listar-tipo-usuario-movil',
    },
    {
        id: 12,
        titulo: 'Gestionar Sub Tipo Usuario Web',
        descripcion: 'Sub Tipo Usuario Web',
        icono: carIcon,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'configuracion/gestionar-sub-tipo-usuario-web/listar-sub-tipo-usuario-web',
    },
];