import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const/estilos-const";
import Select from "react-select";
// import { INT_TIPO_USUARIO_SUPERVISOR_MOVIL } from "../../utils/const/int-const";
import "../../style/style-cargando.css";
import cargandoCard from "../../components/cargando-card";
import sinDataCard from "../../components/sin-data-card";
import { crearUsuarioWeb, existeUsuarioWeb, modificarUsuarioWeb, } from "../../api/usuario-web";
import { obtenerListaSubTipoUsuarioWeb } from "../../api/sub-tipo-usuario-web";

export default function UsuarioWeb() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [titulo, setTitulo] = useState("CREAR USUARIO WEB");
  const [listaSubTipoUsuarioWeb, setListaSubTipoUsuarioWeb] = useState([]);
  const [subTipoUsuarioWebSelected, setSubTipoUsuarioWebSelected] = useState(null);

  useEffect(() => {
    console.log("entro a UsuarioWeb");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setUsuario(data.params);
        setTitulo(data.titulo);

        let subTipoUsuarioWeb = await obtenerListaSubTipoUsuarioWeb(true)
        // console.log('subTipoUsuarioWeb');
        // console.log(subTipoUsuarioWeb);
        setListaSubTipoUsuarioWeb(subTipoUsuarioWeb);
        if (data.params.idSubTipoUsuarioWeb !== null) {
          const subTipoUsuario = subTipoUsuarioWeb.find(e => e.value === data.params.idSubTipoUsuarioWeb);
          if (subTipoUsuario !== undefined) {
            setSubTipoUsuarioWebSelected(subTipoUsuario);
          }
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setUsuario({ ...usuario, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    try {
      // console.log('handleAceptarClick');
      // lógica para guardar los cambios
      // console.log('usuario');
      // console.log(usuario);
      const valido = await validarUsuario();
      // console.log('valido');
      // console.log(valido);
      if (valido === false) {
        return;
      }
      let objEnviar = {};
      objEnviar.id = usuario.id;
      objEnviar.nombre = usuario.nombre;
      objEnviar.apellido = usuario.apellido;
      objEnviar.usuario = usuario.usuario;
      objEnviar.contrasena = usuario.contrasena;
      objEnviar.nota = usuario.nota;
      objEnviar.idSubTipoUsuarioWeb = null;
      if (subTipoUsuarioWebSelected !== null) {
        objEnviar.idSubTipoUsuarioWeb = subTipoUsuarioWebSelected.value;
      }
      const creado = await existeUsuarioWeb(objEnviar);
      if (creado === true) {
        alert("Usuario: " + objEnviar.usuario + " ya existe.");
        return;
      }
      // return;
      if (usuario.id <= 0) {
        // console.log('crear correo');
        const creado = await crearUsuarioWeb(objEnviar);
        // console.log('creado');
        // console.log(creado);
        if (creado === true) {
          alert('Se creo correctamente');
          history.goBack();
        }
        if (creado === false) {
          alert('No se pudo crear');
        }
      }
      if (usuario.id > 0) {
        // console.log('actualizar correo');
        const modificado = await modificarUsuarioWeb(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          history.goBack();
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
    } catch (err) {
      console.log('handleAceptarClick err');
      console.log(err);
      alert('No se pudo continuar, por favor revise los campos');
    }
  };

  const validarUsuario = async () => {
    try {
      let error = '';
      if (usuario === undefined || usuario === null || usuario.nombre === undefined === usuario.nombre === null || String(usuario.nombre).length === 0) {
        error = error + 'Necesita ingresar nombre\n';
      }
      if (usuario.apellido === undefined === usuario.apellido === null || String(usuario.apellido).length === 0) {
        error = error + 'Necesita ingresar apellido\n';
      }
      if (usuario.usuario === undefined === usuario.usuario === null || String(usuario.usuario).length === 0) {
        error = error + 'Necesita ingresar usuario\n';
      }
      if (usuario.contrasena === undefined === usuario.contrasena === null || String(usuario.contrasena).length === 0) {
        error = error + 'Necesita ingresar contraseña\n';
      }
      if (usuario.nota === undefined || usuario.nota === null) {
        usuario.nota = '';
      }
      if (String(error).length === 0) {
        return true;
      }
      if (String(error).length > 0) {
        alert(error);
      }
      return false;
    } catch (err) {
      console.log('validarUsuario err');
      console.log(err);
      return false;
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleTipoUsuarioWebSelected = (event) => {
    try {
      setSubTipoUsuarioWebSelected(event);
    } catch (err) {
      console.log('handleTipoUsuarioWebSelected err');
      console.log(err);
    }
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && usuario === null) {
        return sinDataCard();
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  SUB TIPO USUARIO WEB
                </label>
                <Select options={listaSubTipoUsuarioWeb} isSearchable='true'
                  isClearable='true'
                  value={subTipoUsuarioWebSelected}
                  onChange={(event) => handleTipoUsuarioWebSelected(event)}
                  placeholder='SELECCIONE SUB TIPO USUARIO'
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nombre" className="mb-2 font-bold">
                  NOMBRE
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={usuario.nombre}
                  onChange={handleInputChange}
                  placeholder="NOMBRE"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="apellido" className="mb-2 font-bold">
                  APELLIDO
                </label>
                <input
                  type="text"
                  id="apellido"
                  name="apellido"
                  value={usuario.apellido}
                  onChange={handleInputChange}
                  placeholder="APELLIDO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={100}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  USUARIO
                </label>
                <input
                  type="text"
                  id="usuario"
                  name="usuario"
                  value={usuario.usuario}
                  onChange={handleInputChange}
                  placeholder="USUARIO"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={45}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              {usuario !== null && usuario.id === 0 &&
                <div
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <label htmlFor="contrasena" className="mb-2 font-bold">
                    CONTRASEÑA
                  </label>
                  <input
                    type="text"
                    id="contrasena"
                    name="contrasena"
                    value={usuario.contrasena}
                    onChange={handleInputChange}
                    placeholder="CONTRASEÑA"
                    className="p-2 border border-gray-300 rounded"
                    maxLength={45}
                  />
                </div>
              }
            </div>

          </div>
          <br />
          {/* <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
            </div>

          </div>
          <br /> */}
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="nota" className="mb-2 font-bold">
                  NOTA
                </label>
                <textarea
                  type="text"
                  id="nota"
                  name="nota"
                  value={usuario.nota}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="NOTA"
                  className="p-2 border border-gray-300 rounded"
                  maxLength={512}
                />
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('UsuarioMovil render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
