import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { crearIcono } from "../../../api/gestionar-imagenes";
import "../../../style/style-cargando.css";
import { ESTILO_BOTON_LISTAR_ATRAS } from "../../../utils/const/estilos-const";
import cargandoCard from "../../../components/cargando-card";

export default function Icono() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [icono, setIcono] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formatError, setFormatError] = useState(false);
  const [sizeError, setSizeError] = useState(false);


  useEffect(() => {
    iniciar();
  }, []);


  const iniciar = () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setIcono(data.params);
        // setTitulo(data.titulo);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIcono({ ...icono, [name]: value });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    uploadImage(file);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    uploadImage(file);
  };

  const uploadImage = (file) => {
    if (file) {
      if (file.size > 500 * 1024) {
        setSizeError(true);
      } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
        setSizeError(false);
        setFormatError(false);
        setSelectedImage(URL.createObjectURL(file));
      } else {
        setFormatError(true);
      }
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    setFormatError(false);
    setSizeError(false);
  };


  const handleSaveImage = () => {
    try {
      if (icono.nombre === undefined || icono.nombre === null || String(icono.nombre).trim().length === 0) {
        alert('Debe ingresar un nombre');
        return;
      }
      // console.log('selectedImage');
      // console.log(selectedImage);
      // const fileType = selectedImage.type;
      // console.log('fileType');
      // console.log(fileType);
      const imgElement = document.createElement("img");
      imgElement.src = selectedImage;
      imgElement.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;
        const context = canvas.getContext("2d");
        context.drawImage(imgElement, 0, 0);
        const base64Image = canvas.toDataURL("image/png");
        let fileType = base64Image.match(/^data:(.+);base64,/)[1];
        fileType = removePrefix(fileType, 'image/');
        // console.log("Imagen guardada en el sistema:", base64Image);
        // console.log('fileType');
        // console.log(fileType);
        // console.log(icono);
        let objEnviar = {};
        objEnviar.base64 = base64Image;
        objEnviar.descripcion = String(icono.nombre).trim().toString();
        objEnviar.tipoArchivo = '.' + fileType;
        let guardado = await crearIcono(objEnviar);
        // console.log('guardado');
        // console.log(guardado);
        if (guardado === true) {
          alert('Se guardo correctamente');
          handleAtrasClick();
        }
        if (guardado === false) {
          alert('No se pudo guardar el icono');
        }
      };
    } catch (err) {
      console.log('handleSaveImage err');
      console.log(err);
    }
  };

  const removePrefix = (string, prefix) => {
    return string.replace(prefix, '');
  }

  const handleAtrasClick = () => {
    // nextPage("listar-iconos", null);
    history.goBack();
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      return (
        <div className="max-w-lg mx-auto pt-4">
          <div
            style={{
              paddingTop: 5,
              // paddingBottom: 5,
              // paddingRight: 10,
              // paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="nombre" className="mb-2 font-bold">
                NOMBRE ICONO
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={icono.nombre}
                onChange={handleInputChange}
                placeholder="NOMBRE ICONO"
                className="p-2 border border-gray-300 rounded"
                maxLength={100}
              />
            </div>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div className="mt-4">
            <label htmlFor="imageUpload" className="block text-lg font-medium text-gray-700 mb-2">
              Subir imagen:
            </label>
            <div
              id="dropZone"
              className={`w-70vw h-30vh border-2 border-gray-300 border-dashed rounded-md flex items-center justify-center ${selectedImage ? 'hidden' : ''
                }`}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                id="imageUpload"
                type="file"
                className="hidden"
                onChange={handleImageUpload}
                accept=".jpg, .png"
              />
              <div className="text-center">
                <p className="text-sm text-gray-500">Arrastra y suelta la imagen aquí o</p>
                <button
                  type="button"
                  className="mt-2 py-2 px-4 border border-gray-300 rounded-md bg-red-300 text-gray-700 font-medium hover:bg-red-400"
                  onClick={() => document.getElementById('imageUpload').click()}
                >
                  Subir imágenes
                </button>
              </div>
            </div>
            {formatError && (
              <p className="text-red-500 text-sm mt-2">Formato de archivo no válido. Por favor, selecciona un archivo .jpg o .png.</p>
            )}
            {sizeError && (
              <p className="text-red-500 text-sm mt-2">El tamaño del archivo excede el límite de 500 KB.</p>
            )}
          </div>

          {selectedImage && (
            <div className="mt-4">
              <img src={selectedImage} alt="Preview" className="w-full rounded-md" />
              <div className="flex justify-between mt-2">
                <button
                  type="button"
                  className="py-1 px-2 bg-red-300 text-white font-medium rounded hover:bg-red-400"
                  onClick={handleDeleteImage}
                >
                  Eliminar imagen
                </button>
                <button
                  type="button"
                  className="py-1 px-2 bg-green-600 text-white font-medium rounded hover:bg-green-400"
                  onClick={handleSaveImage}
                >
                  Aceptar
                </button>
              </div>
            </div>
          )}
        </div>
      );
    } catch (err) {
      console.log('Icono render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
