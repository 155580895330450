import "../style/style-cargando.css";

const sinAccesoCard = () => {
    return (
        <div className="flex flex-col items-center mt-8">
            <div style={{ marginTop: 10 }}>
                <p>Sin Acceso Al Modulo...</p>
            </div>
        </div>
    );
}

export default sinAccesoCard;