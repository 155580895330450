import moment from "moment/moment";
import { POST, PUT, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaUsuariosWeb(obj) {
  try {
    let rows = [];
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/obtener-lista-usuario-web/",
      {
        headers: getHeaders(),
        method: POST,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      for (let i = 0; i < body.length; i++) {
        body[i].fechaProceso = moment(body[i].fechaProceso).format(
          "DD/MM/YYYY HH:mm"
        );
        rows.push(body[i]);
      }
    }
    return rows;
  } catch (err) {
    console.log("obtenerListaUsuariosWeb err");
    console.log(err);
    return [];
  }
}

export async function crearUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/guardar-usuario-web/",
      {
        headers: getHeaders(),
        method: POST,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("crearUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function modificarUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/modificar-usuario-web/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("modificarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function deshabilitarUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/deshabilitar-usuario-web/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("deshabilitarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function habilitarUsuarioWeb(obj) {
  try {
    let result = false;
    const { code } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/habilitar-usuario-web/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      result = true;
    }
    return result;
  } catch (err) {
    console.log("habilitarUsuarioWeb err");
    console.log(err);
    return false;
  }
}

export async function existeUsuarioWeb(obj) {
  try {
    let result = false;
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/existe-usuario-web/",
      {
        headers: getHeaders(),
        method: PUT,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS && body.length === 1) {
      const cantidad = body[0].cantidad;
      result = cantidad === 0 ? false : true;
    }
    return result;
  } catch (err) {
    console.log("existeUsuarioWeb err");
    console.log(err);
    return false;
  }
}