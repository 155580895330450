import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
// import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
// import { TRUE } from "../../utils/const-bool";
// import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { Settings } from "feather-icons-react/build/IconComponents";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import { SELECT_HABILITADO_DESHABILITADO } from "../../utils/const/const-select-habilitado-deshabilitado";
import Select from "react-select";
import { sharedNextPage } from "../../utils/shared-functions";
import { INT_TIPO_USUARIO_ADMINISTRADOR_WEB } from "../../utils/const/int-const";
import { deshabilitarUsuarioWeb, habilitarUsuarioWeb, obtenerListaUsuariosWeb } from "../../api/usuario-web";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';

export default function ListarUsuarioWeb() {
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "ACCIONES", visible: true, },
    { key: "id", title: "CODIGO", visible: false, },
    { key: "nombre", title: "NOMBRE", filter: true, visible: true, },
    { key: "apellido", title: "APELLIDO", filter: true, visible: true, },
    { key: "usuario", title: "USUARIO", filter: true, visible: true, },
    { key: "nota", title: "NOTA", filter: true, visible: true },
    { key: "idSubTipoUsuarioWeb", title: "idSubTipoUsuarioWeb", filter: true, visible: false },
    { key: "subTipoUsuarioWeb", title: "SUB TIPO USUARIO WEB", filter: true, visible: true },
    { key: "fechaProceso", title: "FECHA PROCESO", filter: true, visible: true, },
  ];
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [modalConfirmarHabilitarVisible, setModalConfirmarHabilitarVisible] =
    useState(false);
  const [usuarioMovilSelected, setUsuarioMovilSelected] = useState(null);
  // const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [estadoHabilitadoSelected, setEstadoHabilitadoSelected] = useState(null);

  const [exportar, setExportar] = useState(true);
  // const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  // const [configuracionOrden, setConfiguracionOrden] = useState(null);
  // const [ascendente, setAscendente] = useState(false);
  // const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    // console.log("entro a ListarUsuarioWeb");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      /*let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_USUARIOS;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_USUARIOS;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_USUARIO_MOVIL;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_USUARIOS_MOVIL });
      console.log('configuracionScreenInfo');
      console.log(configuracionScreenInfo);*/
      let objConfiguracionEnviar = {};
      // objConfiguracionEnviar.descendente = true;
      // objConfiguracionEnviar.ascendente = false;
      objConfiguracionEnviar.habilitado = estadoHabilitadoSelected !== null ? estadoHabilitadoSelected.value : null;
      /*if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        console.log('configuracionOrden');
        console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          console.log('configuracionHeaders');
          console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);*/

      let lista = [];

      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        lista = await obtenerListaUsuariosWeb(objConfiguracionEnviar);
      }
      // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      //   if (permiso.accesoAlModulo === TRUE) {
      //     setTieneAccesoAlModulo(true);
      //     lista = await obtenerListaUsuariosMovil(objConfiguracionEnviar);
      //   }
      // }

      agregarAccionesALista(lista, null);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaUsuarios(lista);
      }
      if (lista.length > 0) {
        const objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => editarUsuario(param),
        };
        const objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        const objHabilitar = {
          label: "Habilitar",
          color: "blue",
          onClick: (param) => cambiarEstadoHabilitar(param),
        };
        // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        //   if (permiso.nuevo === TRUE) {
        //     accesoBotonNuevo = '';
        //   }
        // }
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          // console.log('element');
          // console.log(element);
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            // acciones.push(objDeshabilitar);
            if (element.habilitado === 1) {
              acciones.push(objDeshabilitar);
            }
            if (element.habilitado === 0) {
              acciones.push(objHabilitar);
            }
          }
          // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          //   if (permiso.modificar === TRUE) {
          //     acciones.push(objModificar);
          //   }
          //   if (permiso.eliminarDeshabilitar === TRUE) {
          //     // acciones.push(objDeshabilitar);
          //     if (element.habilitado === 1) {
          //       acciones.push(objDeshabilitar);
          //     }
          //     if (element.habilitado === 0) {
          //       acciones.push(objHabilitar);
          //     }
          //   }
          // }
          lista[i].acciones = acciones;
        }
        setListaUsuarios(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const editarUsuario = (data) => {
    try {
      console.log("editarUsuario");
      console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR USUARIO WEB";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      sharedNextPage("usuario-web", obj, history);
    } catch (err) {
      console.log("editarUsuario err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setUsuarioMovilSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.nombre = "";
    data.apellido = "";
    data.usuario = "";
    data.nota = "";
    data.telefono = "";
    obj.titulo = "CREAR USUARIO WEB";
    obj.params = data;
    // nextPage("usuario-web", obj);
    sharedNextPage("usuario-web", obj, history);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (usuarioMovilSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = usuarioMovilSelected.id;
    if (usuarioMovilSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarUsuarioWeb(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  }

  const cambiarEstadoHabilitar = async (data) => {
    try {
      setUsuarioMovilSelected(data);
      setModalConfirmarHabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstadoHabilitar err");
      console.log(err);
    }
  };

  const handleCancelModalHabilitar = () => {
    setModalConfirmarHabilitarVisible(false);
  };

  const handleConfirmModalHabilitar = async () => {
    try {
      // console.log("Modal confirmado!");
      // console.log('tipoCarroceriaSelected');
      // console.log(tipoCarroceriaSelected);
      setModalConfirmarHabilitarVisible(false);
      let obj = {};
      obj.id = usuarioMovilSelected.id;
      const habilitado = await habilitarUsuarioWeb(obj);
      // console.log('habilitado');
      // console.log(habilitado);
      if (habilitado === true) {
        alert("Se habilito correctamente");
        reset();
      }
      if (habilitado === false) {
        alert("No se pudo habilitar sub tipo carroceria");
      }
    } catch (err) {
      console.log("handleConfirmModalHabilitar err");
      console.log(err);
    }
  };

  const handleEstado = (event) => {
    setEstadoHabilitadoSelected(event);
  }

  const handleBuscarClick = () => {
    reset();
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA USUARIO WEB
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div className="flex align-center" >
              <Select
                options={SELECT_HABILITADO_DESHABILITADO}
                isSearchable={true}
                isClearable={true}
                value={estadoHabilitadoSelected}
                onChange={(event) => handleEstado(event)}
                placeholder="Seleccione Estado"
              />
              <div
                style={{ width: 10 }}
              ></div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscarClick}
                style={{
                }}
              >
                BUSCAR
              </button>
            </div>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
            // onClick={}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaUsuarios} headers={gridHeaders}
            exportar={exportar} />
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar usuario?"
          />
          <ModalConfirmar
            isOpen={modalConfirmarHabilitarVisible}
            onCancel={handleCancelModalHabilitar}
            onConfirm={handleConfirmModalHabilitar}
            message="¿Esta seguro de habilitar este usuario?"
          />
          {/* <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          /> */}
        </div>
      );
    } catch (err) {
      console.log('index render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
